import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Load from 'react-loader-spinner'

import styles from './Loader.module.css'

const Loader = (props: any) => (
	<div className={styles.loaderWrapper} {...props}>
		<Load type="Puff" color="#00BFFF" height={100} width={100} timeout={3000} />
	</div>
)

export default Loader
