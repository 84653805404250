import Router from 'next/router'

import { User } from '../src/generated/graphql'

export const fallBackAvatar = (seed: string) =>
	`https://avatars.dicebear.com/api/bottts/${seed}.svg`

export const redirectIfMissingUserDate = (currentUser: User) => {
	if (!currentUser?.id) {
		Router.push('/')
	} else if (!currentUser?.phoneNumber && !currentUser?.postalCode) {
		Router.push('/set-info')
	}
}
export const hostName = () =>
	typeof window !== 'undefined'
		? `${window.location.protocol}//${window.location.host}/`
		: ''
