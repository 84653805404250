/**
 * @class SpotifyAuth
 */

import React, { Component } from 'react'
import scopes from './Scopes'
import getRedirectUri from './generateUrl'

import styles from './SpotifyAuth.module.css'
import t from 'prop-types'
import SpotifyLogo from './SpotifyLogo'
import { getHash } from './getHash'
import { hostName } from '../../utils'

const authDefaults = {
	scopes: [
		scopes.userReadEmail,
		scopes.userTopRead,
		scopes.playlistReadCollaborative,
		scopes.playlistReadPrivate,
	],
	clientID: '14e919ca1cd440b8bd70db691a9dd287',
	redirectUri: hostName(),
}

export const handleSpotifyAuth =
	({
		checkCanSubmit = () => true,
		clientID = authDefaults.clientID,
		scopes = authDefaults.scopes,
		showDialog = false,
		onAccessToken,
	}) =>
	(event) => {
		event.preventDefault()

		// If can't submit then noop
		if (!checkCanSubmit()) {
			return
		}

		const redirectUri = getRedirectUri(
			clientID,
			scopes,
			authDefaults.redirectUri,
			showDialog
		)

		if (window.location !== window.parent.location) {
			const loginWindow = window.open(redirectUri)
			window.addEventListener(
				'message',
				(event) => {
					if (
						event.data.type !== 'react-spotify-auth' ||
						!event.data.accessToken
					) {
						return
					}

					loginWindow.close()
					onAccessToken(event.data.accessToken)
				},
				false
			)
		} else {
			window.location = redirectUri
		}
	}

class SpotifyAuth extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isAuthenticatedWithSpotify: false,
		}
	}

	componentDidMount() {
		const accessToken = getHash().access_token

		if (accessToken) {
			if (!this.props.noCookie) {
				document.cookie = `spotifyAuthToken=${accessToken}; max-age=${60 * 60};`
			}
			if (this.props.localStorage) {
				window.localStorage.setItem('spotifyAuthToken', accessToken)
			}
			window.opener?.postMessage(
				{ type: 'react-spotify-auth', accessToken },
				'*'
			)
			this.props.onAccessToken(accessToken)
		}
	}

	handleClick = handleSpotifyAuth(this.props)

	render() {
		return (
			<button
				className={this.props.btnClassName || styles.rsaSpotifyBtn}
				onClick={(event) => this.handleClick(event)}
			>
				{!this.props.noLogo && (
					<SpotifyLogo className={this.props.logoClassName} />
				)}
				<span>{this.props.title}</span>
			</button>
		)
	}
}

SpotifyAuth.propTypes = {
	redirectUri: t.string,
	clientID: t.string,
	scopes: t.arrayOf(t.string),
	onAccessToken: t.func,
	checkCanSubmit: t.func,
	logoClassName: t.string,
	title: t.string,
	noLogo: t.bool,
	noCookie: t.bool,
	showDialog: t.bool,
	localStorage: t.bool,
}

SpotifyAuth.defaultProps = {
	redirectUri: authDefaults.redirectUri,
	clientID: authDefaults.clientID,
	scopes: authDefaults.scopes,
	onAccessToken: (token) => {},
	checkCanSubmit: () => true,
	title: 'Continue with Spotify',
	localStorage: false,
	noLogo: false,
	noCookie: false,
	showDialog: false,
}

export default SpotifyAuth
