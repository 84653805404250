import { useRef } from 'react'
import styles from './VideoBackground.module.css'

interface VidoeBackgroundProps {
	fileName?: string
}

const musicNotes = 'video-music-notes'
const record = 'video-record'

const VidoeBackground = ({ fileName = musicNotes }: VidoeBackgroundProps) => {
	const videoRef = useRef<HTMLVideoElement>(null)
	const setPlayBack = () => {
		if (videoRef.current) {
			videoRef.current.playbackRate = 1.0
		}
	}
	return (
		<>
			<div className={[styles.bg, styles.overlay].join(' ')}></div>
			<video
				playsInline
				loop
				autoPlay
				muted
				preload="auto"
				ref={videoRef}
				onCanPlay={() => setPlayBack()}
				onLoad={() => setPlayBack()}
				className={[styles.bg, styles.video].join(' ')}
			>
				<source src={`/${fileName}.webm`} type="video/webm" />
				<source src={`/${fileName}.mp4`} type="video/mp4" />
			</video>
		</>
	)
}

export default VidoeBackground
