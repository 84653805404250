import { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { Text, Minitext } from '../Typography'
import AutoSelect from '../SchoolSelect'
import { naSchoolData } from '../SchoolSelect/constants'
import useLocalStorage from '../hooks/useLocalStorage'

import styles from './SetInfoForm.module.css'
import typeographyStyles from '../Typography/Typography.module.css'

interface PhoneNumberProps {
	phoneNumber: string
	setPhoneNumber: (value: string) => void
	hasPhoneNumberError: boolean | undefined
	setHasPhoneNumberError: (value: boolean) => void
}

interface ZipCodeProps {
	zipCode: string
	setZipCode: (value: string) => void
	hasZipCodeError: boolean | undefined
	setHasZipCodeError: (value: boolean) => void
}

interface SchoolValue {
	label: string
	value: string | null
}

interface SchoolProps {
	school: SchoolValue
	setSchool: (value: any) => void
	hasSchoolError: boolean | undefined
	setHasSchoolError: (value: boolean) => void
}

interface SetInfoFormProps extends PhoneNumberProps, ZipCodeProps, SchoolProps {
	formErrorMessage: string | undefined
}

const SetInfoForm = ({
	phoneNumber,
	setPhoneNumber,
	hasPhoneNumberError,
	setHasPhoneNumberError,
	zipCode,
	setZipCode,
	hasZipCodeError,
	setHasZipCodeError,
	school,
	setSchool,
	hasSchoolError,
	setHasSchoolError,
	formErrorMessage,
}: SetInfoFormProps) => {
	const [hasSchool, setHasSchool] = useState(false)
	useEffect(() => {
		setHasSchool(school?.value != naSchoolData.id)
	}, [school?.value])

	const hasFormError = hasSchoolError || hasZipCodeError || hasPhoneNumberError
	return (
		<div className={styles.center}>
			<div
				id="school-wrapper"
				style={{ zIndex: 2, border: hasSchoolError ? '1px solid red' : '' }}
			>
				<AutoSelect
					onChange={(schooldata: any) => {
						setHasSchoolError(false)
						if (schooldata.value != naSchoolData.id) setHasZipCodeError(false)
						setSchool(schooldata)
					}}
					value={school}
				/>
			</div>
			<div
				onClick={() => {
					setSchool({
						...naSchoolData,
					})
				}}
			>
				<Minitext
					style={{ verticalAlign: 'top' }}
					className={typeographyStyles.clickableText}
				>
					{naSchoolData.label}
				</Minitext>
			</div>
			{/* If N/A is selected then require zipcode */}
			{hasSchool ? null : (
				<>
					<div id="zip-wrapper">
						<input
							className={styles.zipInput}
							id="zip"
							name="zip"
							type="text"
							inputMode="numeric"
							pattern="[0-9]{5}"
							placeholder="Zipcode"
							style={hasZipCodeError ? { border: '1px solid red' } : {}}
							value={zipCode}
							onChange={({ target: { value } }) => {
								value = value.replace(/(?![0-9])./gim, '')
								if (value.length > 5) return
								setZipCode(value)
								setHasZipCodeError(false)
							}}
						/>
					</div>
					<br />
				</>
			)}
			<PhoneInput
				country={'us'}
				onlyCountries={['us']}
				inputProps={{
					name: 'phone',
					required: true,
				}}
				value={phoneNumber}
				// @ts-ignore
				style={hasPhoneNumberError ? { border: '1px solid red' } : {}}
				onChange={(phone) => {
					setHasPhoneNumberError(false)
					const newNumber = phone[0] != '1' ? '1' + phone : phone
					setPhoneNumber(newNumber)
				}}
			/>
			<Minitext>
				Your friends will find you by phone #, which will never be shared.
			</Minitext>
			<br />
			{hasFormError && (
				<div>
					<Text style={{ color: 'red' }}>{formErrorMessage}</Text> <br />
				</div>
			)}
		</div>
	)
}

export default SetInfoForm

export const useSetInfoFormState = (): SetInfoFormProps & {
	setFormErrorMessage: (value: string) => void
} => {
	const [school, setSchool] = useLocalStorage('onboardSchool', {
		label: 'Find your school',
		value: null,
	})
	const [phoneNumber, setPhoneNumber] = useLocalStorage(
		'onboardPhoneNumber',
		''
	)
	const [zipCode, setZipCode] = useLocalStorage('onboardZipCode', '')

	const [hasSchoolError, setHasSchoolError] = useState(false)
	const [hasZipCodeError, setHasZipCodeError] = useState(false)
	const [hasPhoneNumberError, setHasPhoneNumberError] = useState(false)
	const [formErrorMessage, setFormErrorMessage] = useState<string>()

	return {
		phoneNumber,
		setPhoneNumber,
		hasPhoneNumberError,
		setHasPhoneNumberError,
		zipCode,
		setZipCode,
		hasZipCodeError,
		setHasZipCodeError,
		school,
		setSchool,
		hasSchoolError,
		setHasSchoolError,
		formErrorMessage,
		setFormErrorMessage,
	}
}

export const getHasValidPhone = (number: string) => number?.length === 11
export const getHasValidZipCode = (zip: string) => {
	const validZipCodeRegex = RegExp(/^[0-9]{5}$/)
	return zip ? validZipCodeRegex.test(zip) : false
}

export const isSubmissionValid = ({
	phoneNumber,
	setHasPhoneNumberError,
	zipCode,
	setHasZipCodeError,
	school,
	setHasSchoolError,
	setFormErrorMessage,
}: {
	setFormErrorMessage: (value: string) => void
} & Pick<
	SetInfoFormProps,
	| 'phoneNumber'
	| 'setHasPhoneNumberError'
	| 'zipCode'
	| 'setHasZipCodeError'
	| 'school'
	| 'setHasSchoolError'
>) => {
	const isNASchool = school?.value && school.value == naSchoolData.id
	const hasZipError = Boolean(!getHasValidZipCode(zipCode) && isNASchool)
	const hasSchoolError = !school?.value
	const hasPhoneError = !getHasValidPhone(phoneNumber)

	if (hasZipError || hasSchoolError || hasPhoneError) {
		setHasZipCodeError(hasZipError)
		setHasSchoolError(hasSchoolError)
		setHasPhoneNumberError(hasPhoneError)
		if (hasZipError) setFormErrorMessage('Please include a valid zip code')
		else if (hasSchoolError) setFormErrorMessage('Please select your school')
		else if (hasPhoneError)
			setFormErrorMessage('Please enter a valid phone number')
		return false
	}
	return true
}
