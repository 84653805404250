import type { NextPage } from 'next'
import Router from 'next/router'
import Link from 'next/link'
import { useEffect, useRef } from 'react'

import styles from '../styles/Home.module.css'

// @ts-ignore
import { SpotifyAuth } from '../components/SpotifyAuth'
import Cookies from 'js-cookie'

import AppNameTitle from '../components/AppNameTitle'
import { Subtitle, Minitext } from '../components/Typography'
import MainLayout from '../components/MainLayout'
import useLocalStorage from '../components/hooks/useLocalStorage'
import { appDescription } from '../utils/constants'
import {
	useGetUserLazyQuery,
	User,
	useSignUpOrLoginMutation,
} from '../src/generated/graphql'
import HeaderNav from '../components/HeaderNav'
import SetInfoForm, {
	getHasValidPhone,
	getHasValidZipCode,
	useSetInfoFormState,
	isSubmissionValid,
} from '../components/SetInfoForm'
import VideoBackground from '../components/VideoBackground'
import Login from '../components/Login'
import Loader from '../components/Loader'
import WindowHeightContainer from '../components/WindowHeightContainer'

const redirectIfAuthenticated = (currentUser: User) => {
	// This cookie is written automatically by the library
	const spotifyToken = Cookies.get('spotifyAuthToken')
	if (
		currentUser?.phoneNumber &&
		(currentUser?.school || currentUser?.postalCode)
	) {
		Router.replace('/compare')
	} else if (currentUser?.id && spotifyToken) {
		// There is a Spotify token but user hasn't set info yet
		// Reauthenticating should return the full user object and hit the first condition above
		Router.replace('/set-info')
	}
}

const Home: NextPage = () => {
	const [user, setUser] = useLocalStorage<User>('currentUser', {})
	const [invitingFriendId] = useLocalStorage('invitingFriendId', '')

	const [getUser, { data: friendData }] = useGetUserLazyQuery()

	useEffect(() => {
		if (invitingFriendId) {
			getUser({
				variables: { userId: invitingFriendId },
			})
		}
	}, [invitingFriendId])

	useEffect(() => {
		redirectIfAuthenticated(user)
	}, [user])

	const [setSignUpOrLoginMutation, { data, loading, error, reset }] =
		useSignUpOrLoginMutation()

	useEffect(() => {
		if (data?.signUpOrLogin?.id) {
			setUser(data?.signUpOrLogin)
		}
	}, [data?.signUpOrLogin?.id])

	useEffect(() => {
		if (
			error &&
			error?.graphQLErrors?.[0]?.extensions?.exception?.constraint ===
				'users_phone_number_unique'
		) {
			setHasPhoneNumberError(true)
			setFormErrorMessage('Please enter a valid phone number')
		}
		reset()
	}, [error])

	const {
		phoneNumber,
		setPhoneNumber,
		hasPhoneNumberError,
		setHasPhoneNumberError,
		zipCode,
		setZipCode,
		hasZipCodeError,
		setHasZipCodeError,
		school,
		setSchool,
		hasSchoolError,
		setHasSchoolError,
		formErrorMessage,
		setFormErrorMessage,
	} = useSetInfoFormState()

	const submissionData = useRef({
		phoneNumber,
		zipCode,
		school,
	})

	// Workaround for bug where these values aren't passed into isSubmissionValid correctly
	useEffect(() => {
		submissionData.current = {
			phoneNumber,
			zipCode,
			school,
		}
	}, [phoneNumber, zipCode, school])

	const onAccessToken = (token: string) => {
		!data?.signUpOrLogin?.id &&
			!loading &&
			setSignUpOrLoginMutation({
				variables: {
					input: {
						spotifyToken: token,
						schoolId: school?.value ?? undefined,
						phoneNumber: getHasValidPhone(phoneNumber)
							? phoneNumber
							: undefined,
						postalCode: getHasValidZipCode(zipCode) ? zipCode : undefined,
						invitingFriendId: invitingFriendId ? invitingFriendId : undefined,
					},
				},
			}).catch(console.error)
	}

	const description = friendData?.getMe?.name
		? `${friendData.getMe.name} wants to see if you're music BFFs`
		: appDescription

	return (
		<WindowHeightContainer>
			<VideoBackground />
			<HeaderNav
				hideLogo
				rightButton={<Login onAccessToken={onAccessToken} />}
			/>
			<MainLayout style={{ flex: 1 }}>
				<div style={{ margin: '0 auto' }}>
					<AppNameTitle />
				</div>
				<Subtitle className={styles.description}>{description}</Subtitle>
				<SetInfoForm
					{...{
						phoneNumber,
						setPhoneNumber,
						hasPhoneNumberError,
						setHasPhoneNumberError,
						zipCode,
						setZipCode,
						hasZipCodeError,
						setHasZipCodeError,
						school,
						setSchool,
						hasSchoolError,
						setHasSchoolError,
						formErrorMessage,
					}}
				/>
				<div className={styles.spotifyWrapper}>
					{loading ? (
						<Loader />
					) : (
						<div>
							<SpotifyAuth
								checkCanSubmit={() =>
									isSubmissionValid({
										...submissionData.current,
										...{
											setHasPhoneNumberError,
											setHasZipCodeError,
											setHasSchoolError,
											setFormErrorMessage,
										},
									})
								}
								onAccessToken={onAccessToken}
							/>
							<Minitext>
								By clicking Continue, you agree to our{' '}
								<Link href="/tos">
									<a>Terms</a>
								</Link>{' '}
								and{' '}
								<Link href="/privacy">
									<a>Privacy Policy</a>
								</Link>
							</Minitext>
						</div>
					)}
				</div>
			</MainLayout>
		</WindowHeightContainer>
	)
}

export default Home
