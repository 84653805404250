import { Text } from '../Typography'
import { handleSpotifyAuth } from '../SpotifyAuth/SpotifyAuth'

const Login = ({
	onAccessToken,
}: {
	onAccessToken: (token: string) => void
}) => {
	return (
		<div
			style={{ cursor: 'pointer' }}
			onClick={handleSpotifyAuth({ onAccessToken })}
		>
			<Text>Login</Text>
		</div>
	)
}

export default Login
